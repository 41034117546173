export const TIME_SESION = 3; // ==> Minutes

export const INACTIVITY_TIMEOUT = 1; // ==> Minutes

// ==> Format date
export const FULL_DATE_ES = "DD/MM/YYYY hh:mm A";
export const DATE_ES = "DD/MM/YYYY";
export const FULL_DATE_EN = "MM/DD/YYYY hh:mm A";
export const DATE_EN = "MM/DD/YYYY";

// ==> For encrypt
export const PASSWORD = "BFA_SV#4gH9!qRz2X"; // Password for encrypt
export const SUSTKEY1 = "BFA_SV-#4gH9!qRz2X-15tWoP@K7" // 3 prosiciones, separadas por -

export const VERSION_APP = "1.5.7";