// Register services worker
const BASE_URL = process.env.PUBLIC_URL;

const checkAndDeleteOtherSw = async () => {
  const registers = await navigator.serviceWorker.getRegistrations();
  registers.forEach(reg => {
    if (reg.scope !== `${BASE_URL}/`) reg.unregister();
  });
};

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

const registerValidSW = async (swUrl) => {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) return;
      installingWorker.onstatechange = () => {

        if (!(installingWorker?.state === "installed")) return;

        if (navigator.serviceWorker.controller) {
          console.log("New content is available; please refresh.");
        } else {
          console.log("Content is cached for offline use.");
        }
      };
    };
  } catch (err) {
    // Consider using a more robust error handling strategy here
    console.error("Error when registering service worker", err);
  }
};

const checkValidServiceWorker = async (swUrl) => {
  try {
    const response = await fetch(swUrl, {
      headers: { "Service-Worker": "script" },
    });
    const contentType = response.headers.get("content-type");
    if (
      response.status === 404 ||
      (contentType != null && contentType.indexOf("javascript") === -1)
    ) {
      const registration = await navigator.serviceWorker.ready;
      registration.unregister();
      window.location.reload();
    } else registerValidSW(swUrl);
  } catch (err) {
    // Consider using a more robust error handling strategy here
    console.error("Error when checking service worker", err);
  }
};

const register = async () => {
  if ("serviceWorker" in navigator) {
    await checkAndDeleteOtherSw();

    window.addEventListener("load", async () => {
      const swUrl = `${BASE_URL}/sw.js`;

      if (isLocalhost) checkValidServiceWorker(swUrl);
      else registerValidSW(swUrl);
    });
  }
};

const requestNotificationPermission = async () => {
  if (!("Notification" in window)) return;

  const permission = await Notification.requestPermission();
  if (permission !== "granted") return;
}

register();
requestNotificationPermission();